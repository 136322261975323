
.registration-card{
    width: 480px;
    background-color: gold;
    position: absolute;
    z-index: 99999;
    top: 3%;
    padding: 20px 20px;

}
.registration-card form{
    color: white;
}
.registration-input{
    outline: none !important;
    border: none !important;
    background-color:rgb(29, 25, 25) ;
    height: 35px;
    width: 95%;
    border-radius: 5px;
    color: white !important;

}
.registration-btn{
    width: 100%;
    background-color: #009B2f;
    color: white;
    font-family: "mukta";
}
.registration-btn:hover{
    background-color:#fa5004 ;
}
.bth{
    color: white;
    font-size: 13px;
    background: #1d1919;
    padding: 10px;
    border-radius: 7px;
    width: 95%
}
.bth span{
    color:#fa5004;
    
}