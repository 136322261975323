
.background-img {
  background: url(../../Media/login_bg4.jpg);
  background-size:auto;
  width: 100vw;
  height: 100vh;
  position: absolute;
  /* background-size: 100% 100%; */
  /* background-repeat: no-repeat; */
  /* position: relative; */
  z-index:99;
  overflow: hidden;
}
.img-div{
    height: 80px;
    width: 80px;

}

@media only screen and (max-width: 600px) {
    .login-card{
        height: 420px !important;
        width: 400px;
        background-color: gold;
        position: absolute;
        z-index:99999;
        top: 2% !important;
        padding: 15px 0px;
    
    }
}
.login-card{
    height: 370px;
    width: 400px;
    background-color: gold;
    position: absolute;
    z-index:99999;
    top: 15%;
    padding: 15px 0px;

}
.login-card form{
    color: white;
}
.login-input{
    outline: none !important;
    border: none !important;
    background-color:rgb(29, 25, 25) ;
    height: 35px;
    width: 100%;
    border-radius: 5px;
    color: white !important;

}
.login-btn{
    width:98%;
    background-color: #009B2f;
    color: white;
    font-family: "mukta";
}
.login-btn:hover{
    background-color:#fa5004 ;
}
.bth{
    color: white;
    font-size: 9px;
}
.bth span{
    color:#fa5004;
    
}


 /* <--- Login Modal CSS---> */


 .login-modal-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: none;

}



.login-modal-container{
    position: absolute;
    width: 470px;
    height: fit-content;
    
    background-color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index:999999;
    border: none;

}


.Info-modal-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: none;

}



.Info-modal-container{
    position: absolute;
    width: 400px;
    height: 350px;
    
    background-color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index:999999;
    border: none;

}